import React from "react";
import { Box, Typography, Fade, Stack } from "@mui/material";

export const TextContainer = (props: TextContainerProps) => (
    <Box minHeight="100vh" width="80%" display="flex" alignItems="center" id={`phone-slide-section-${props.order}`} ref={props.sectionRef}>
        <Fade in={props.active}>
            <Stack justifyContent="space-between">
                <Box>
                    <Typography gutterBottom variant="h2" style={{ whiteSpace: "pre-wrap" }}>{props.title}</Typography>
                    <Typography paragraph style={{ whiteSpace: "pre-wrap", opacity: 0.9 }}>{props.description}</Typography>
                </Box>

                <Box marginTop="62px">
                    {props.StepperElement}
                </Box>
            </Stack>
        </Fade>
    </Box>
);

type TextContainerProps = {
    order: number;
    sectionRef: React.MutableRefObject<HTMLDivElement>;
    active: boolean;
    title: string;
    description: string;
    StepperElement: React.ReactNode;
};
