export const policy = `<div>
  <h1
    style="
      padding-top: 3pt;
      padding-left: 110pt;
      text-indent: 0pt;
      line-height: 144%;
      text-align: center;
    "
  >
    Regulamin i Polityka Prywatno<span class="s1">ś</span>ci Aplikacji Mobilnej
    Sowa MOBI
  </h1>
  <p style="padding-top: 14pt; text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 1</h2>
  <h2
    style="
      padding-left: 110pt;
      text-indent: 0pt;
      line-height: 17pt;
      text-align: center;
    "
  >
    Definicje
  </h2>
  <p style="text-indent: 0pt; line-height: 13pt; text-align: center">
    Okre<span class="s2">ś</span>lenia u<span class="s2">ż</span>yte w
    niniejszym Regulaminie i Polityce Prywatno<span class="s2">ś</span>ci
    oznaczaj<span class="s2">ą</span>:
  </p>
  <ol id="l1">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 9pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Aplikacja Mobilna </b>- oprogramowanie Sowa MOBI umo<span class="s2"
          >ż</span
        >liwiaj<span class="s2">ą</span>ce korzystanie przez U<span class="s2"
          >ż</span
        >ytkownika lub Zarejestrowanego u<span class="s2">ż</span>ytkownika z
        okre<span class="s2">ś</span>lonych Us<span class="s2">ł</span>ug za
        pomoc<span class="s2">ą </span>mobilnego urz<span class="s2">ą</span
        >dzenia ko<span class="s2">ń</span>cowego, mo<span class="s2">ż</span
        >liwe do pobrania ze sklepu internetowego Google Play (dla systemu
        Android);
      </p>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Regulamin i Polityka Prywatno</b><span class="s3">ś</span><b>ci </b>-
        niniejszy Regulamin i Polityka Prywatno<span class="s2">ś</span>ci
        Aplikacji Mobilnej; stanowi<span class="s2">ą</span>cy regulamin w
        rozumieniu art. 8 Ustawy o <span class="s2">ś</span>wiadczeniu us<span
          class="s2"
          >ł</span
        >ug drog<span class="s2">ą </span>elektroniczn<span class="s2">ą</span>;
      </p>
    </li>
    <li data-list-text="3.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Us</b><span class="s3">ł</span><b>ugodawca </b>- SOKRATES-software
        Leszek Masady<span class="s2">ń</span>ski; adres do korespondencji:
        SOKRATES-software, ul. Warszawska 92 A, 61-047 Pozna<span class="s2"
          >ń</span
        >, NIP: 7781008453;
      </p>
      <p
        style="
          padding-left: 41pt;
          text-indent: 0pt;
          line-height: 13pt;
          text-align: left;
        "
      >
        REGON: 632009902;
      </p>
    </li>
    <li data-list-text="4.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Biblioteka </b>- podmiot, który u<span class="s2">ż</span>ytkuje
        system biblioteczny Sowa (SowaSQL Premium), spe<span class="s2">ł</span
        >nia okre<span class="s2">ś</span>lone warunki i wyrazi<span class="s2"
          >ł </span
        >zgod<span class="s2">ę </span>na dost<span class="s2">ę</span>p do
        swoich us<span class="s2">ł</span>ug za po<span class="s2">ś</span
        >rednictwem Aplikacji Mobilnej;
      </p>
    </li>
    <li data-list-text="5.">
      <h4
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 106%;
          text-align: justify;
        "
      >
        Katalog Biblioteki
        <span class="s4"
          >- katalog Biblioteki dostępny zarówno dla Użytkownika jak i
          Zalogowanego użytkownika za pośrednictwem Aplikacji Mobilnej;</span
        >
      </h4>
    </li>
    <li data-list-text="6.">
      <p style="padding-left: 41pt; text-indent: -17pt; text-align: left">
        <b>Konto </b>- indywidualne konto U<span class="s2">ż</span>ytkownika w
        Bibliotece, pozwalaj<span class="s2">ą</span>ce na korzystanie z Us<span
          class="s2"
          >ł</span
        >ug;
      </p>
    </li>
    <li data-list-text="7.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -17pt;
          text-align: left;
        "
      >
        <b>U</b><span class="s3">ż</span><b>ytkownik </b>- osoba która
        pobra<span class="s2">ł</span>a i zainstalowa<span class="s2">ł</span>a
        Aplikacj<span class="s2">ę </span>Mobiln<span class="s2">ą</span>;
      </p>
    </li>
    <li data-list-text="8.">
      <h3
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: left;
        "
      >
        Zalogowany U<span class="s3">ż</span>ytkownik <span class="p">- U</span
        ><span class="s2">ż</span
        ><span class="p"
          >ytkownik, który posiada Konto w co najmniej jednej Bibliotece i jest
          do niej zalogowany w Aplikacji Mobilnej;</span
        ><span class="s4">.</span>
      </h3>
    </li>
    <li data-list-text="9.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -17pt;
          line-height: 13pt;
          text-align: left;
        "
      >
        <b>Us</b><span class="s3">ł</span><b>ugobiorca - </b>U<span class="s2"
          >ż</span
        >ytkownik lub Zalogowany U<span class="s2">ż</span>ytkownik;
      </p>
    </li>
    <li data-list-text="10.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -17pt;
          text-align: left;
        "
      >
        <b>Rejestracja </b>- jednorazowa czynno<span class="s2">ść </span
        >maj<span class="s2">ą</span>ca na celu za<span class="s2">ł</span
        >o<span class="s2">ż</span>enie Konta przez Us<span class="s2">ł</span
        >ugobiorc<span class="s2">ę</span>;
      </p>
    </li>
    <li data-list-text="11.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Si</b><span class="s3">ł</span><b>a wy</b><span class="s3">ż</span
        ><b>sza </b>- zdarzenie zewn<span class="s2">ę</span>trzne, niezale<span
          class="s2"
          >ż</span
        >ne od Us<span class="s2">ł</span>ugodawcy, niemo<span class="s2"
          >ż</span
        >liwe do przewidzenia i do zapobie<span class="s2">ż</span>enia, w
        szczególno<span class="s2">ś</span>ci wojny, katastrofy naturalne,
        strajki oraz akty wydane przez organy w<span class="s2">ł</span>adzy
        publicznej;
      </p>
    </li>
    <li data-list-text="12.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Us</b><span class="s3">ł</span><b>uga </b>- ka<span class="s2"
          >ż</span
        >da us<span class="s2">ł</span>uga <span class="s2">ś</span>wiadczona
        drog<span class="s2">ą </span>elektroniczn<span class="s2">ą </span
        >przez Us<span class="s2">ł</span>ugodawc<span class="s2">ę </span>na
        zasadach okre<span class="s2">ś</span>lonych w Regulaminie i Polityce
        Prywatno<span class="s2">ś</span>ci, udost<span class="s2">ę</span
        >pniana dla Us<span class="s2">ł</span>ugobiorcy za po<span class="s2"
          >ś</span
        >rednictwem Aplikacji Mobilnej;
      </p>
    </li>
    <li data-list-text="13.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        <b>Ustawa o </b><span class="s3">ś</span><b>wiadczeniu us</b
        ><span class="s3">ł</span><b>ug drog</b><span class="s3">ą </span
        ><b>elektroniczn</b><span class="s3">ą </span>- ustawa z dnia 18 lipca
        2002 r. o <span class="s2">ś</span>wiadczeniu us<span class="s2">ł</span
        >ug drog<span class="s2">ą </span>elektroniczn<span class="s2">ą </span
        >(Dz.U. 2002r., Nr 144, poz. 1244 ze zm.).
      </p>
    </li>
  </ol>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 2</h2>
  <h2
    style="
      padding-left: 110pt;
      text-indent: 0pt;
      line-height: 17pt;
      text-align: center;
    "
  >
    Postanowienia ogólne
  </h2>
  <ol id="l2">
    <li data-list-text="1.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: left;
        "
      >
        Regulamin i Polityka Prywatno<span class="s2">ś</span>ci okre<span
          class="s2"
          >ś</span
        >la warunki i zasady <span class="s2">ś</span>wiadczenia Us<span
          class="s2"
          >ł</span
        >ug za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej.
      </p>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: left;
        "
      >
        Umowa o <span class="s2">ś</span>wiadczenie Us<span class="s2">ł</span
        >ug drog<span class="s2">ą </span>elektroniczn<span class="s2">ą </span
        >z Us<span class="s2">ł</span>ugodawc<span class="s2">ą </span>zostaje
        zawarta z chwil<span class="s2">ą </span>zainstalowania Aplikacji
        Mobilnej. Umowa zostaje zawarta na czas nieoznaczony, chyba
        <span class="s2">ż</span>e
      </p>
      <p
        style="
          padding-top: 3pt;
          padding-left: 41pt;
          text-indent: 0pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        z postanowie<span class="s2">ń </span>niniejszego Regulaminu i Polityki
        Prywatno<span class="s2">ś</span>ci lub postanowie<span class="s2"
          >ń </span
        >szczególnych dla danej Us<span class="s2">ł</span>ugi wynika inaczej.
      </p>
    </li>
    <li data-list-text="3.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Pobranie i zainstalowanie Aplikacji Mobilnej jest równoznaczne z
        akceptacj<span class="s2">ą </span>przez Us<span class="s2">ł</span
        >ugobiorc<span class="s2">ę </span>warunków
        <span class="s2">ś</span>wiadczenia Us<span class="s2">ł</span>ug
        okre<span class="s2">ś</span>lonych w niniejszym Regulaminie i Polityce
        Prywatno<span class="s2">ś</span>ci.
      </p>
    </li>
    <li data-list-text="4.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Korzystanie z Us<span class="s2">ł</span>ug jest bezp<span class="s2"
          >ł</span
        >atne. Koszty transmisji danych potrzebnych do pobrania Aplikacji
        Mobilnej oraz do korzystania z Aplikacji Mobilnej, Us<span class="s2"
          >ł</span
        >ugobiorca pokrywa we w<span class="s2">ł</span>asnym zakresie, na
        podstawie umów zawartych z podmiotami
        <span class="s2">ś</span>wiadcz<span class="s2">ą</span>cymi takie
        us<span class="s2">ł</span>ugi.
      </p>
    </li>
    <li data-list-text="5.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Us<span class="s2">ł</span>ugodawca zastrzega sobie prawo do
        modyfikowania technicznego sposobu realizacji Us<span class="s2">ł</span
        >ug stosownie do zakresu i warunków wynikaj<span class="s2">ą</span>cych
        z posiadanych uprawnie<span class="s2">ń </span>oraz mo<span class="s2"
          >ż</span
        >liwo<span class="s2">ś</span>ci technicznych.
      </p>
    </li>
    <li data-list-text="6.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Us<span class="s2">ł</span>ugobiorca jest zobowi<span class="s2">ą</span
        >zany do przestrzegania postanowie<span class="s2">ń </span>Regulaminu i
        Polityki Prywatno<span class="s2">ś</span>ci.
      </p>
    </li>
    <li data-list-text="7.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W celu zapewnienia bezpiecze<span class="s2">ń</span>stwa przekazu
        komunikatów w zwi<span class="s2">ą</span>zku ze
        <span class="s2">ś</span>wiadczonymi Us<span class="s2">ł</span>ugami,
        Us<span class="s2">ł</span>ugodawca ma prawo podejmowa<span class="s2"
          >ć ś</span
        >rodki techniczne i organizacyjne odpowiednie do stopnia zagro<span
          class="s2"
          >ż</span
        >enia bezpiecze<span class="s2">ń</span>stwa
        <span class="s2">ś</span>wiadczonych przez niego Us<span class="s2"
          >ł</span
        >ug.
      </p>
    </li>
    <li data-list-text="8.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Us<span class="s2">ł</span>ugodawca zastrzega sobie prawo do przesy<span
          class="s2"
          >ł</span
        >ania Us<span class="s2">ł</span>ugobiorcy informacji technicznych i
        systemowych dotycz<span class="s2">ą</span>cych funkcjonowania Aplikacji
        Mobilnej.
      </p>
    </li>
  </ol>
  <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 3</h2>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">
    Zasady odpowiedzialno<span class="s5">ś</span>ci
  </h2>
  <ol id="l3">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 13pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: justify;
        "
      >
        W celu korzystania z Us<span class="s2">ł</span>ug, Us<span class="s2"
          >ł</span
        >ugobiorca powinien we w<span class="s2">ł</span>asnym zakresie
        uzyska<span class="s2">ć </span>dost<span class="s2">ę</span>p do
        Internetu i urz<span class="s2">ą</span>dzenia ko<span class="s2"
          >ń</span
        >cowego, za pomoc<span class="s2">ą </span>którego mo<span class="s2"
          >ż</span
        >liwe jest korzystanie z Us<span class="s2">ł</span>ug. W zakresie
        <span class="s2">ś</span>wiadczenia Us<span class="s2">ł</span>ug
        Us<span class="s2">ł</span>ugodawca nie jest dostawc<span class="s2"
          >ą </span
        >us<span class="s2">ł</span>ugi Internetu.
      </p>
    </li>
    <li data-list-text="2.">
      <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
        Korzystanie z Aplikacji Mobilnej jest mo<span class="s2">ż</span>liwe
        pod warunkiem spe<span class="s2">ł</span>nienia nast<span class="s2"
          >ę</span
        >puj<span class="s2">ą</span>cych wymaga<span class="s2">ń </span
        >technicznych: platforma Android (od wersji 5.0 i nowsze).
      </p>
    </li>
    <li data-list-text="3.">
      <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
        Us<span class="s2">ł</span>ugobiorca jest wy<span class="s2">łą</span
        >cznie odpowiedzialny za zapewnienie technicznej zgodno<span class="s2"
          >ś</span
        >ci pomi<span class="s2">ę</span>dzy urz<span class="s2">ą</span>dzeniem
        ko<span class="s2">ń</span>cowym oraz systemem teleinformatycznym,
        u<span class="s2">ż</span>ywanymi w celu korzystania z Us<span
          class="s2"
          >ł</span
        >ug za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej.
      </p>
    </li>
    <li data-list-text="4.">
      <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
        Us<span class="s2">ł</span>ugodawca nie ponosi odpowiedzialno<span
          class="s2"
          >ś</span
        >ci za nieprawid<span class="s2">ł</span>owe dzia<span class="s2"
          >ł</span
        >anie Aplikacji Mobilnej spowodowane przyczynami od niego niezale<span
          class="s2"
          >ż</span
        >nymi, w szczególno<span class="s2">ś</span>ci dzia<span class="s2"
          >ł</span
        >aniem Si<span class="s2">ł</span>y wy<span class="s2">ż</span>szej czy
        nieprawid<span class="s2">ł</span>owym dzia<span class="s2">ł</span
        >aniem zasobów systemowych (hardware lub software) u<span class="s2"
          >ż</span
        >ywanych przez Us<span class="s2">ł</span>ugobiorc<span class="s2"
          >ę </span
        >w celu korzystania z Us<span class="s2">ł</span>ug, które nie
        zosta<span class="s2">ł</span>y udost<span class="s2">ę</span>pnione
        przez Us<span class="s2">ł</span>ugodawc<span class="s2">ę </span>w
        ramach <span class="s2">ś</span>wiadczenia danej Us<span class="s2"
          >ł</span
        >ugi.
      </p>
    </li>
    <li data-list-text="5.">
      <p style="padding-left: 41pt; text-indent: -17pt; text-align: justify">
        Us<span class="s2">ł</span>ugodawca nie ponosi odpowiedzialno<span
          class="s2"
          >ś</span
        >ci za:
      </p>
      <ol id="l4">
        <li data-list-text="a)">
          <p style="padding-left: 77pt; text-indent: -18pt; text-align: left">
            utrat<span class="s2">ę </span>przez Us<span class="s2">ł</span
            >ugobiorc<span class="s2">ę </span>danych zwi<span class="s2"
              >ą</span
            >zanych z Us<span class="s2">ł</span>ugami spowodowan<span
              class="s2"
              >ą </span
            >Si<span class="s2">łą </span>wy<span class="s2">ż</span>sz<span
              class="s2"
              >ą </span
            >lub okoliczno<span class="s2">ś</span>ciami le<span class="s2"
              >żą</span
            >cymi po stronie Us<span class="s2">ł</span>ugobiorcy;
          </p>
        </li>
        <li data-list-text="b)">
          <p style="padding-left: 77pt; text-indent: -18pt; text-align: left">
            negatywne konsekwencje udost<span class="s2">ę</span>pnienia osobie
            trzeciej urz<span class="s2">ą</span>dzenia ko<span class="s2"
              >ń</span
            >cowego umo<span class="s2">ż</span>liwiaj<span class="s2">ą</span
            >cego dost<span class="s2">ę</span>p do Aplikacji Mobilnej;
          </p>
        </li>
        <li data-list-text="c)">
          <p style="padding-left: 77pt; text-indent: -18pt; text-align: left">
            skutki korzystania przez Us<span class="s2">ł</span>ugobiorc<span
              class="s2"
              >ę </span
            >z Aplikacji Mobilnej w sposób sprzeczny z obowi<span class="s2"
              >ą</span
            >zuj<span class="s2">ą</span>cym prawem i postanowieniami Regulaminu
            i Polityki Prywatno<span class="s2">ś</span>ci;
          </p>
        </li>
        <li data-list-text="d)">
          <p style="padding-left: 77pt; text-indent: -18pt; text-align: left">
            skutki wykorzystania w trakcie procesu Rejestracji danych osoby,
            która nie wyrazi<span class="s2">ł</span>a na to zgody, przez
            nieuprawnionego Us<span class="s2">ł</span>ugobiorc<span class="s2"
              >ę</span
            >;
          </p>
        </li>
        <li data-list-text="e)">
          <p style="padding-left: 77pt; text-indent: -17pt; text-align: left">
            skutki udost<span class="s2">ę</span>pnienia przez Us<span
              class="s2"
              >ł</span
            >ugobiorc<span class="s2">ę </span>swoich danych do logowania w
            Bibliotece.
          </p>
        </li>
      </ol>
    </li>
    <li data-list-text="6.">
      <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
        Us<span class="s2">ł</span>ugodawca zastrzega sobie prawo do przerw
        technicznych, a tak<span class="s2">ż</span>e do czasowego zaprzestania
        lub ograniczenia <span class="s2">ś</span>wiadczenia Us<span class="s2"
          >ł</span
        >ug bez powiadomienia w przypadkach szczególnych maj<span class="s2"
          >ą</span
        >cych wp<span class="s2">ł</span>yw na bezpiecze<span class="s2">ń</span
        >stwo systemów Us<span class="s2">ł</span>ugodawcy.
      </p>
    </li>
  </ol>
  <h2
    style="
      padding-top: 1pt;
      padding-left: 110pt;
      text-indent: 0pt;
      text-align: center;
    "
  >
    § 4
  </h2>
  <h2 style="text-indent: 0pt; text-align: center">
    Ogólne warunki korzystania z Aplikacji Mobilnej i
    <span class="s5">ś</span>wiadczenia Us<span class="s5">ł</span>ug
  </h2>
  <ol id="l5">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 11pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Aplikacja Mobilna przeznaczona jest dla czytelników Bibliotek u<span
          class="s2"
          >ż</span
        >ytkuj<span class="s2">ą</span>cych system biblioteczny Sowa (SowaSQL
        Premium), które wyrazi<span class="s2">ł</span>y zgod<span class="s2"
          >ę </span
        >na na dost<span class="s2">ę</span>p do swoich us<span class="s2"
          >ł</span
        >ug za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej i
        spe<span class="s2">ł</span>niaj<span class="s2">ą </span>okre<span
          class="s2"
          >ś</span
        >lone warunki.
      </p>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Aplikacja Mobilna zapewnia tylko te Us<span class="s2">ł</span>ugi,
        które udost<span class="s2">ę</span>pnia dana Biblioteka, a które
        opisane s<span class="s2">ą </span>w jej regulaminie us<span class="s2"
          >ł</span
        >ug, w szczególno<span class="s2">ś</span>ci dost<span class="s2"
          >ę</span
        >p do Katalogu oraz Konta czytelnika Biblioteki.
      </p>
    </li>
    <li data-list-text="3.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Aplikacja Mobilna umo<span class="s2">ż</span>liwia przeszukiwanie
        Katalogu Biblioteki zarówno inteligentne, jak i zaawansowane za
        pomoc<span class="s2">ą </span>rozmaitych atrybutów (tytu<span
          class="s2"
          >ł</span
        >, autor, temat, forma i typ, gatunek, itp.). Ta Us<span class="s2"
          >ł</span
        >uga dost<span class="s2">ę</span>pna jest dla U<span class="s2">ż</span
        >ytkownika i Zalogowanego U<span class="s2">ż</span>ytkownika.
      </p>
    </li>
    <li data-list-text="4.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Zalogowanie do Konta w Aplikacji Mobilnej wymaga podania adresu e-mail
        oraz has<span class="s2">ł</span>a. Je<span class="s2">ż</span>eli
        Us<span class="s2">ł</span>ugobiorca posiada ju<span class="s2">ż </span
        >Konto w Bibliotece, mo<span class="s2">ż</span>e utworzy<span
          class="s2"
          >ć </span
        >konto w Aplikacji Mobilnej podaj<span class="s2">ą</span>c ten same
        login i has<span class="s2">ł</span>o jak do posiadanego Konta.
      </p>
    </li>
    <li data-list-text="5.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Zalogowany U<span class="s2">ż</span>ytkownik, w zale<span class="s2"
          >ż</span
        >no<span class="s2">ś</span>ci od Us<span class="s2">ł</span>ug
        oferowanych przez Bibliotek<span class="s2">ę</span>, w szczególno<span
          class="s2"
          >ś</span
        >ci mo<span class="s2">ż</span>e:
      </p>
      <ol id="l6">
        <li data-list-text="a.">
          <p
            style="
              padding-left: 77pt;
              text-indent: -17pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            przeszukiwa<span class="s2">ć </span>Katalog Biblioteki,
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style="padding-left: 77pt; text-indent: -17pt; text-align: justify"
          >
            sprawdza<span class="s2">ć </span>dost<span class="s2">ę</span
            >pno<span class="s2">ść </span>ksi<span class="s2">ąż</span>ek i
            innych zbiorów,
          </p>
        </li>
        <li data-list-text="c.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 77pt;
              text-indent: -18pt;
              line-height: 108%;
              text-align: justify;
            "
          >
            zamawia<span class="s2">ć</span>, rezerwowa<span class="s2">ć</span
            >, wypo<span class="s2">ż</span>ycza<span class="s2">ć </span>i
            prolongowa<span class="s2">ć </span>udost<span class="s2">ę</span
            >pnione do wypo<span class="s2">ż</span>yczania zbiory biblioteczne,
          </p>
        </li>
        <li data-list-text="d.">
          <p
            style="
              padding-left: 77pt;
              text-indent: -17pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            przegl<span class="s2">ą</span>da<span class="s2">ć </span
            >histori<span class="s2">ę </span>wypo<span class="s2">ż</span
            >ycze<span class="s2">ń</span>,
          </p>
        </li>
        <li data-list-text="e.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 77pt;
              text-indent: -18pt;
              line-height: 108%;
              text-align: left;
            "
          >
            otrzymywa<span class="s2">ć </span>informacje o zmianach stanu
            zamówie<span class="s2">ń</span>, nadchodz<span class="s2">ą</span
            >cych terminach zwrotów lub ich przekroczeniu,
          </p>
        </li>
        <li data-list-text="f.">
          <p
            style="
              padding-left: 77pt;
              text-indent: -17pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            mie<span class="s2">ć </span>dost<span class="s2">ę</span>p do
            numeru i kodu karty czytelnika,
          </p>
        </li>
        <li data-list-text="g.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 77pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            przegl<span class="s2">ą</span>da<span class="s2">ć </span>stan
            rozlicze<span class="s2">ń</span>,
          </p>
        </li>
        <li data-list-text="h.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 77pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            edytowa<span class="s2">ć </span>niektóre dane w swoim profilu.
          </p>
        </li>
      </ol>
    </li>
    <li data-list-text="6.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Aplikacja Mobilna umo<span class="s2">ż</span>liwia U<span class="s2"
          >ż</span
        >ytkownikowi elektroniczne wys<span class="s2">ł</span>anie do
        Biblioteki danych niezb<span class="s2">ę</span>dnych do za<span
          class="s2"
          >ł</span
        >o<span class="s2">ż</span>enia Konta (Rejestracji). Proces Rejestracji
        wymaga potwierdzenia to<span class="s2">ż</span>samo<span class="s2"
          >ś</span
        >ci U<span class="s2">ż</span>ytkownika przez pracownika Biblioteki na
        miejscu w jej siedzibie oraz innych czynno<span class="s2">ś</span>ci
        okre<span class="s2">ś</span>lonych regulaminem Biblioteki.
      </p>
    </li>
    <li data-list-text="7.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W celu Rejestracji U<span class="s2">ż</span>ytkownik musi poda<span
          class="s2"
          >ć </span
        >adres e-mail (który mo<span class="s2">ż</span>e by<span class="s2"
          >ć </span
        >równocze<span class="s2">ś</span>nie loginem do Konta) oraz
        ustanowi<span class="s2">ć </span>has<span class="s2">ł</span>o do
        Konta. Na podany adres e-mail przes<span class="s2">ł</span>ane zostanie
        potwierdzenie z kodem cyfrowym niezb<span class="s2">ę</span>dnym do
        wpisania w Aplikacji Mobilnej. Ponadto w zale<span class="s2">ż</span
        >no<span class="s2">ś</span>ci od Biblioteki wymagane mo<span class="s2"
          >ż</span
        >e by<span class="s2">ć </span>podanie przez U<span class="s2">ż</span
        >ytkownika nast<span class="s2">ę</span>puj<span class="s2">ą</span>cych
        informacji:
      </p>
      <ol id="l7">
        <li data-list-text="a)">
          <p
            style="
              padding-left: 90pt;
              text-indent: -17pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            imi<span class="s2">ę </span>i nazwisko,
          </p>
        </li>
        <li data-list-text="b)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            data urodzenia,
          </p>
        </li>
        <li data-list-text="c)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            p<span class="s2">ł</span>e<span class="s2">ć</span>,
          </p>
        </li>
        <li data-list-text="d)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            adres zamieszkania,
          </p>
        </li>
        <li data-list-text="e)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            adres do korespondencji,
          </p>
        </li>
        <li data-list-text="f)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            numer telefonu.
          </p>
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Zalogowany U<span class="s2">ż</span>ytkownik mo<span class="s2"
              >ż</span
            >e te<span class="s2">ż </span>zamie<span class="s2">ś</span>ci<span
              class="s2"
              >ć</span
            >:
          </p>
        </li>
        <li data-list-text="g)">
          <p
            style="
              padding-top: 1pt;
              padding-left: 90pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            obraz (awatar).
          </p>
        </li>
      </ol>
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: 0pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W procesie Rejestracji wymagane jest równie<span class="s2">ż </span
        >potwierdzenie zapoznania si<span class="s2">ę </span>przez U<span
          class="s2"
          >ż</span
        >ytkownika z regulaminem Biblioteki. Link do regulaminu Biblioteki
        dost<span class="s2">ę</span>pny jest mi<span class="s2">ę</span>dzy
        innymi w Aplikacji Mobilnej podczas procesu Rejestracji.
      </p>
    </li>
    <li data-list-text="8.">
      <p
        style="
          padding-top: 3pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W trakcie Rejestracji, jak równie<span class="s2">ż </span>podczas
        korzystania z Us<span class="s2">ł</span>ug, Us<span class="s2">ł</span
        >ugobiorca zobowi<span class="s2">ą</span>zany jest do podania danych
        zgodnych z prawd<span class="s2">ą</span>, dok<span class="s2">ł</span
        >adnych i aktualnych, które nie wprowadzaj<span class="s2">ą </span>w
        b<span class="s2">łą</span>d oraz nie naruszaj<span class="s2">ą </span
        >praw osób trzecich, a tak<span class="s2">ż</span>e do aktualizowania
        danych podanych przy Rejestracji. Us<span class="s2">ł</span>ugobiorca
        jest wy<span class="s2">łą</span>cznie odpowiedzialny za tre<span
          class="s2"
          >ść </span
        >podanych przez siebie danych.
      </p>
    </li>
    <li data-list-text="9.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej Biblioteka
        mo<span class="s2">ż</span>e przesy<span class="s2">ł</span>a<span
          class="s2"
          >ć </span
        >powiadomienia push z informacjami o terminach zwrotów wypo<span
          class="s2"
          >ż</span
        >yczonych materia<span class="s2">ł</span>ów.
      </p>
    </li>
    <li data-list-text="10.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Po zainstalowaniu Aplikacji Mobilnej powiadomienia push s<span
          class="s2"
          >ą </span
        >domy<span class="s2">ś</span>lnie w<span class="s2">łą</span>czone.
        Us<span class="s2">ł</span>ugobiorca mo<span class="s2">ż</span>e
        samodzielnie wy<span class="s2">łą</span>czy<span class="s2">ć </span
        >powiadomienia push.
      </p>
    </li>
    <li data-list-text="11.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        U<span class="s2">ż</span>ywanie Aplikacji Mobilnej przez Us<span
          class="s2"
          >ł</span
        >ugobiorc<span class="s2">ę </span>jest bezp<span class="s2">ł</span
        >atne. W ramach korzystania z Aplikacji Mobilnej prezentowane s<span
          class="s2"
          >ą </span
        >reklamy w formie banerów przez serwis reklam Google AdMob - regu<span
          class="s2"
          >ł</span
        >y dost<span class="s2">ępne na stronie </span
        ><a href="https://admob.google.com/home/" class="s12" target="_blank"
          ><span
          style="
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: underline;
            font-size: 11pt;
          "
          >https://admob.google.com/home/</span
        ></a
        >
        .
      </p>
    </li>
  </ol>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 5</h2>
  <h2 style="text-indent: 0pt; text-align: center">
    Informacje dotycz<span class="s5">ą</span>ce ochrony danych osobowych
  </h2>
  <ol id="l8">
    <li data-list-text="1.">
      <h3
        style="
          padding-top: 13pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W zakresie informacji zwi<span class="s3">ą</span>zanych z Zalogowanym
        U<span class="s3">ż</span>ytkownikiem, Administratorem danych osobowych
        jest Biblioteka <span class="p">(w rozumieniu przepisów rozporz</span
        ><span class="s2">ą</span
        ><span class="p"
          >dzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 r. w sprawie ochrony osób fizycznych w zwi</span
        ><span class="s2">ą</span
        ><span class="p"
          >zku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przep</span
        ><span class="s2">ł</span
        ><span class="p"
          >ywu takich danych oraz uchylenia dyrektywy 95/46/WE (tzw. ogólne
          rozporz</span
        ><span class="s2">ą</span
        ><span class="p">dzenie o ochronie danych) (dalej: RODO)</span>, w
        której Zalogowany U<span class="s3">ż</span>ytkownik posiada Konto lub
        oczekuje na potwierdzenie przez Bibliotek<span class="s3">ę </span
        >za<span class="s3">ł</span>o<span class="s3">ż</span>enia Konta<span
          class="p"
          >.</span
        >
      </h3>
      <ol id="l9">
        <li data-list-text="1.1.">
          <h3
            style="
              padding-left: 55pt;
              text-indent: -21pt;
              line-height: 108%;
              text-align: justify;
            "
          >
            Do kontaktu w sprawach zwi<span class="s3">ą</span>zanych z
            ochron<span class="s3">ą </span>danych osobowych, których Biblioteka
            jest Administratorem, w<span class="s3">ł</span>a<span class="s3"
              >ś</span
            >ciwy jest adres wskazany w Polityce bezpiecze<span class="s3"
              >ń</span
            >stwa Biblioteki.
          </h3>
        </li>
        <li data-list-text="1.2.">
          <h3
            style="
              padding-left: 55pt;
              text-indent: -21pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Us<span class="s3">ł</span>ugodawca w w/w zakresie jest
            <u>jedynie</u> Podmiotem przetwarzaj<span class="s3">ą</span>cym.
          </h3>
        </li>
      </ol>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-top: 1pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: justify;
        "
      >
        W Aplikacji Mobilnej przetwarzane s<span class="s2">ą </span>informacje
        w tym metadane zwi<span class="s2">ą</span>zane z u<span class="s2"
          >ż</span
        >ytkowaniem Aplikacji Mobilnej wymienione w pkt. 2.4. , których
        Administratorem jest Us<span class="s2">ł</span>ugodawca.
      </p>
      <ol id="l10">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            Do kontaktu z Us<span class="s2">ł</span>ugodawc<span class="s2"
              >ą </span
            >w sprawach zwi<span class="s2">ą</span>zanych z ochron<span
              class="s2"
              >ą </span
            >danych osobowych, o których mowa w pkt. 2, a których Us<span
              class="s2"
              >ł</span
            >ugodawca jest Administratorem, w<span class="s2">ł</span>a<span
              class="s2"
              >ściwy jest adres wskazany w § 1 pkt 3 lub adres poczty
              elektronicznej</span
            ><a href="mailto:rodo@sokrates.pl" class="s12" target="_blank"
              > <span
              style="
                font-family: Calibri, sans-serif;
                font-style: normal;
                font-weight: normal;
                text-decoration: underline;
                font-size: 11pt;
              "
              >rodo@sokrates.pl</span
            ></a>.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            Dane osobowe przetwarzane mog<span class="s2">ą </span>by<span
              class="s2"
              >ć </span
            >w celu zawarcia i wykonania Umowy na podstawie art. 6 ust. 1 lit b)
            RODO, realizacji obowi<span class="s2">ą</span>zków prawnych przez
            us<span class="s2">ł</span>ugodawc<span class="s2">ę</span>, na
            podstawie art. 6 ust. 1 lit c) RODO oraz realizacji prawnie
            usprawiedliwionych interesów Us<span class="s2">ł</span>ugodawcy lub
            partnerów Us<span class="s2">ł</span>ugodawcy takich jak wewn<span
              class="s2"
              >ę</span
            >trzne cele administracyjne, na podstawie art.6 ust. 1 lit f) RODO.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            W trakcie trwania sesji, Aplikacja Mobilna mo<span class="s2"
              >ż</span
            >e mie<span class="s2">ć </span>dost<span class="s2">ę</span>p do
            wszystkich danych podanych w trakcie Rejestracji oraz powi<span
              class="s2"
              >ą</span
            >zanych z kontem czytelnika w Bibliotece. Po zamkni<span class="s2"
              >ę</span
            >ciu sesji (zamkni<span class="s2">ę</span>ciu Aplikacji Mobilnej),
            Aplikacja Mobilna przechowuje informacje: awatar, imi<span
              class="s2"
              >ę </span
            >i nazwisko, adres e-mail oraz nazw<span class="s2">ę </span
            >Biblioteki i token. Skorzystanie z Us<span class="s2">ł</span>ugi
            „Zapomnij konto” jest równoznaczne z wylogowaniem si<span class="s2"
              >ę </span
            >Zalogowanego U<span class="s2">ż</span>ytkownika i powoduje
            usuni<span class="s2">ę</span>cie wszelkich informacji o nim z
            urz<span class="s2">ą</span>dzenia, na którym zainstalowana jest
            Aplikacja Mobilna .
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            W trakcie korzystania z us<span class="s2">ł</span>ug Aplikacja
            Mobilna na bie<span class="s2">żą</span>co komunikuje si<span
              class="s2"
              >ę </span
            >z aktualnie zainstalowanym w Bibliotece systemem bibliotecznym, tj.
            wysy<span class="s2">ł</span>a i pobiera informacje na i z serwera,
            który <span class="s2">łą</span>czy si<span class="s2">ę </span>z
            baz<span class="s2">ą </span>danych Biblioteki. Metadane potrzebne
            s<span class="s2">ą </span>do zapewnienia prawid<span class="s2"
              >ł</span
            >owego dzia<span class="s2">ł</span>ania Aplikacji Mobilnej, a
            tak<span class="s2">ż</span>e umo<span class="s2">ż</span>liwienia
          </p>
          <p
            style="
              padding-top: 3pt;
              padding-left: 62pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            Us<span class="s2">ł</span>ugodawcy spe<span class="s2">ł</span
            >nienia wymaga<span class="s2">ń </span>w zakresie bezpiecze<span
              class="s2"
              >ń</span
            >stwa i wymogów prawnych; opracowywania statystyk lub dokonywania
            bada<span class="s2">ń </span>analitycznych (na podstawie prawnie
            usprawiedliwionego interesu). Informacje te mog<span class="s2"
              >ą </span
            >zawiera<span class="s2">ć </span>dane takie jak: adres IP urz<span
              class="s2"
              >ą</span
            >dzenia, geolokalizacja, rodzaj urz<span class="s2">ą</span>dzenia,
            unikalny identyfikator, nazwa systemu operacyjnego, data i godzina
            przesy<span class="s2">ł</span>anych zapyta<span class="s2">ń</span
            >. To, które konkretnie informacje uzyskujemy, zale<span class="s2"
              >ż</span
            >y od rodzaju urz<span class="s2">ą</span>dzenia i oprogramowania, z
            którego korzysta Us<span class="s2">ł</span>ugobiorca i dokonanych
            przez niego ustawie<span class="s2">ń</span>.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            Us<span class="s2">ł</span>ugodawca nie podejmuje wobec Us<span
              class="s2"
              >ł</span
            >ugobiorców decyzji w sposób zautomatyzowany, w tym nie profiluje
            Us<span class="s2">ł</span>ugobiorców.
          </p>
        </li>
        <li data-list-text="2.6.">
          <p
            style="padding-left: 62pt; text-indent: -21pt; text-align: justify"
          >
            Us<span class="s2">ł</span>ugodawca udost<span class="s2">ę</span
            >pnia informacje o Us<span class="s2">ł</span>ugobiorcy wy<span
              class="s2"
              >łą</span
            >cznie, kiedy jest to konieczne dla prawid<span class="s2">ł</span
            >owego <span class="s2">ś</span>wiadczenia Us<span class="s2"
              >ł</span
            >ug i w granicach prawa powszechnie obowi<span class="s2">ą</span
            >zuj<span class="s2">ą</span>cego, podmiotom gwarantuj<span
              class="s2"
              >ą</span
            >cym bezpiecze<span class="s2">ń</span>stwo przetwarzania danych
            osobowych:
          </p>
          <ol id="l11">
            <li data-list-text="2.6.1.">
              <p
                style="
                  padding-left: 104pt;
                  text-indent: -27pt;
                  text-align: justify;
                "
              >
                Aplikacja zbiera dane Zalogowanego U<span class="s2">ż</span
                >ytkownika, które przekierowane s<span class="s2">ą </span>do
                systemu informatycznego Biblioteki w zakresie, jaki jest
                niezb<span class="s2">ę</span>dny do realizacji Us<span
                  class="s2"
                  >ł</span
                >ugi. Szczegó<span class="s2">ł</span>y zawiera regulamin
                korzystania z wybranej przez Us<span class="s2">ł</span
                >ugobiorc<span class="s2">ę </span>Biblioteki. Regulamin ka<span
                  class="s2"
                  >ż</span
                >dej Biblioteki dost<span class="s2">ę</span>pny jest w procesie
                Rejestracji w Aplikacji Mobilnej oraz w Bibliotece lub na jej
                stronie. Administratorem danych Zalogowanego U<span class="s2"
                  >ż</span
                >ytkownika, jak wskazano w pkt. 1 tego paragrafu, jest
                Biblioteka.
              </p>
            </li>
            <li data-list-text="2.6.2.">
              <p
                style="
                  padding-left: 104pt;
                  text-indent: -27pt;
                  text-align: justify;
                "
              >
                Us<span class="s2">ł</span>ugodawca mo<span class="s2">ż</span>e
                udost<span class="s2">ę</span>pnia<span class="s2">ć </span
                >informacje o Us<span class="s2">ł</span>ugobiorcy tak<span
                  class="s2"
                  >ż</span
                >e swoim podwykonawcom, tj. podmiotom zapewniaj<span class="s2"
                  >ą</span
                >cym obs<span class="s2">ł</span>ug<span class="s2">ę </span>lub
                udost<span class="s2">ę</span>pniaj<span class="s2">ą</span>cym
                systemy teleinformatyczne; podmiotom
                <span class="s2">ś</span>wiadcz<span class="s2">ą</span>cym
                us<span class="s2">ł</span>ugi doradcze w tym prawne;
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2.7.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            Aplikacja Mobilna anonimowo zbiera dane statystyczne udost<span
              class="s2"
              >ę</span
            >pniane przez system. Za zbieranie tych danych oraz sposobu u<span
              class="s2"
              >ż</span
            >ytkowania aplikacji odpowiedzialna jest us<span class="s2">ł</span
            >uga Google Analytics, której w<span class="s2">ł</span>a<span
              class="s2"
              >ścicielem jest firma Google Inc. </span
            ><a
              href="https://support.google.com/analytics/answer/6004245?hl=pl"
              class="s8"
              target="_blank"
              >Polityka bezpieczeństwa usługi Google Analytics</a
            >.
          </p>
        </li>
        <li data-list-text="2.8.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            W Aplikacji Mobilnej za wysy<span class="s2">ł</span>anie
            komunikatów push odpowiedzialna jest zewn<span class="s2">ę</span
            >trzna us<span class="s2">ługa Firebase Cloud Messaging. </span
            ><a
              href="https://firebase.google.com/support/privacy"
              class="a"
              target="_blank"
              >Polityka prywatności usługi</a
            >.
          </p>
        </li>
        <li data-list-text="2.9.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            Aplikacja Mobilna korzysta z podkładów mapowych dostarczanych przez system Google Maps API. 
            <a
              href="https://www.google.com/intl/pl/policies/privacy/"
              class="a"
              target="_blank"
              >Polityka prywatności Google inc</a
            >.
          </p>
        </li>
        <li data-list-text="2.10.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            Aplikacja Mobilna korzysta z serwisu reklam Google AdMob. 
            <a
              href="https://policies.google.com/technologies/partner-sites"
              class="s12"
              target="_blank"
              >Jak Google używa danych z witryn i aplikacji</a
            >.
          </p>
        </li>
        <li data-list-text="2.11.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            W trybie anonimowym nie s<span class="s2">ą </span>przekazywane
            <span class="s2">ż</span>adne dane z Aplikacji Mobilnej do systemu
            informatycznego Biblioteki.
          </p>
        </li>
        <li data-list-text="2.12.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            Przys<span class="s2">ł</span>uguj<span class="s2">ą </span>Pa<span
              class="s2"
              >ń</span
            >stwu prawa: dost<span class="s2">ę</span>pu do danych, ich
            sprostowania i prawo do wniesienia sprzeciwu wobec przetwarzania,
            którego dokonujemy w celu realizacji naszych prawnie
            usprawiedliwionych interesów. Ponadto w sytuacjach okre<span
              class="s2"
              >ś</span
            >lonych w prawie ochrony danych osobowych oraz w zakresie tam
            wskazanym, prawo do usuni<span class="s2">ę</span>cia lub
            ograniczenia przetwarzania i prawo do przeniesienia danych
            osobowych.
          </p>
        </li>
        <li data-list-text="2.13.">
          <p
            style="padding-left: 62pt; text-indent: -28pt; text-align: justify"
          >
            Dok<span class="s2">ł</span>adamy wszelkich stara<span class="s2"
              >ń</span
            >, aby przetwarzanie Pa<span class="s2">ń</span>stwa danych
            osobowych odbywa<span class="s2">ł</span>o si<span class="s2"
              >ę </span
            >w sposób zgodny z przepisami prawa. Je<span class="s2">ż</span>eli
            jednak uznaj<span class="s2">ą </span>Pa<span class="s2">ń</span
            >stwo, i<span class="s2">ż </span>dopu<span class="s2">ś</span
            >cili<span class="s2">ś</span>my si<span class="s2">ę </span
            >naruszenia, maj<span class="s2">ą </span>Pa<span class="s2">ń</span
            >stwo prawo wniesienia skargi do organu nadzorczego, tj. Prezesa
            Urz<span class="s2">ę</span>du Ochrony Danych Osobowych.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 6</h2>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">
    Tryb post<span class="s5">ę</span>powania reklamacyjnego
  </h2>
  <ol id="l12">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 11pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: left;
        "
      >
        Us<span class="s2">ł</span>ugobiorcy przys<span class="s2">ł</span>uguje
        prawo do z<span class="s2">ł</span>o<span class="s2">ż</span>enia
        reklamacji w sprawach zwi<span class="s2">ą</span>zanych z
        funkcjonowaniem Aplikacji Mobilnej.
      </p>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-top: 3pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: left;
        "
      >
        Reklamacj<span class="s2">ę </span>mo<span class="s2">ż</span>na sk<span
          class="s2"
          >ł</span
        >ada<span class="s2">ć </span>pisemnie na adres podany w § 1 pkt. 3
        Regulaminu i Polityki Prywatno<span class="s2">ś</span>ci.
      </p>
    </li>
    <li data-list-text="3.">
      <p style="padding-left: 41pt; text-indent: -17pt; text-align: left">
        Us<span class="s2">ł</span>ugodawca rozpatrzy reklamacj<span class="s2"
          >ę </span
        >w terminie do 30 dni od jej otrzymania.
      </p>
    </li>
    <li data-list-text="4.">
      <p style="padding-left: 41pt; text-indent: -18pt; text-align: left">
        Odpowied<span class="s2">ź </span>na reklamacj<span class="s2">ę </span
        >zostanie przes<span class="s2">ł</span>ana na adres podany przez
        Us<span class="s2">ł</span>ugobiorc<span class="s2">ę </span>w
        reklamacji<span class="s4">.</span>
      </p>
    </li>
  </ol>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 7</h2>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">
    W<span class="s5">ł</span>asno<span class="s5">ść </span>intelektualna
  </h2>
  <ol id="l13">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 13pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: left;
        "
      >
        Aplikacja Mobilna oraz prezentowane za jej po<span class="s2">ś</span
        >rednictwem tre<span class="s2">ś</span>ci podlegaj<span class="s2"
          >ą </span
        >ochronie przewidzianej w przepisach prawa powszechnie obowi<span
          class="s2"
          >ą</span
        >zuj<span class="s2">ą</span>cego, w szczególno<span class="s2">ś</span
        >ci przepisach:
      </p>
      <ol id="l14">
        <li data-list-text="a.">
          <p
            style="
              padding-top: 3pt;
              padding-left: 77pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach
            pokrewnych;
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style="
              padding-top: 3pt;
              padding-left: 77pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            ustawy z dnia 27 lipca 2001 r. o ochronie baz danych;
          </p>
        </li>
        <li data-list-text="c.">
          <p
            style="
              padding-top: 3pt;
              padding-left: 77pt;
              text-indent: -17pt;
              text-align: left;
            "
          >
            ustawy z dnia 30 czerwca 2000 r. Prawo w<span class="s2">ł</span
            >asno<span class="s2">ś</span>ci przemys<span class="s2">ł</span
            >owej.
          </p>
        </li>
      </ol>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-top: 3pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: justify;
        "
      >
        Prawa do utworów, baz danych, znaków towarowych udost<span class="s2"
          >ę</span
        >pnianych za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej
        przez Us<span class="s2">ł</span>ugodawc<span class="s2">ę </span
        >przys<span class="s2">ł</span>uguj<span class="s2">ą </span>Us<span
          class="s2"
          >ł</span
        >ugodawcy lub osobom trzecim. W zakresie w jakim prawa te przys<span
          class="s2"
          >ł</span
        >uguj<span class="s2">ą </span>osobom trzecim, Us<span class="s2"
          >ł</span
        >ugodawca udost<span class="s2">ę</span>pnia utwory, bazy danych, znaki
        towarowe na podstawie stosownych umów, w szczególno<span class="s2"
          >ś</span
        >ci umów licencyjnych lub innych stosunków prawnych dopuszczalnych
        przepisami prawa powszechnie obowi<span class="s2">ą</span>zuj<span
          class="s2"
          >ą</span
        >cego.
      </p>
    </li>
    <li data-list-text="3.">
      <p
        style="
          padding-top: 3pt;
          padding-left: 41pt;
          text-indent: -18pt;
          text-align: justify;
        "
      >
        Us<span class="s2">ł</span>ugobiorca mo<span class="s2">ż</span>e
        korzysta<span class="s2">ć </span>z utworów i baz danych udost<span
          class="s2"
          >ę</span
        >pnianych przez Us<span class="s2">ł</span>ugodawc<span class="s2"
          >ę </span
        >za po<span class="s2">ś</span>rednictwem Aplikacji Mobilnej wy<span
          class="s2"
          >łą</span
        >cznie w ramach dozwolonego u<span class="s2">ż</span>ytku osobistego.
      </p>
    </li>
  </ol>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">§ 8</h2>
  <h2 style="padding-left: 110pt; text-indent: 0pt; text-align: center">
    Postanowienia ko<span class="s5">ń</span>cowe
  </h2>
  <ol id="l15">
    <li data-list-text="1.">
      <p
        style="
          padding-top: 17pt;
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Us<span class="s2">ł</span>ugodawca uprawniony jest do dokonywania zmian
        Regulaminu i Polityki Prywatno<span class="s2">ś</span>ci w ka<span
          class="s2"
          >ż</span
        >dym czasie jego obowi<span class="s2">ą</span>zywania, w
        szczególno<span class="s2">ś</span>ci w przypadku zmiany zasad
        <span class="s2">ś</span>wiadczenia Us<span class="s2">ł</span>ug.
      </p>
    </li>
    <li data-list-text="2.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        Aktualna wersja Regulaminu i Polityki Prywatno<span class="s2">ś</span
        >ci jest dost<span class="s2">ę</span>pna w ka<span class="s2">ż</span
        >dym czasie w Aplikacji Mobilnej.
      </p>
    </li>
    <li data-list-text="3.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -18pt;
          line-height: 108%;
          text-align: justify;
        "
      >
        W sprawach nieuregulowanych Regulaminem i Polityk<span class="s2"
          >ą </span
        >Prywatno<span class="s2">ś</span>ci zastosowanie maj<span class="s2"
          >ą </span
        >przepisy prawa powszechnie obowi<span class="s2">ą</span>zuj<span
          class="s2"
          >ą</span
        >cego.
      </p>
    </li>
    <li data-list-text="4.">
      <p
        style="
          padding-left: 41pt;
          text-indent: -17pt;
          line-height: 13pt;
          text-align: justify;
        "
      >
        Niniejszy Regulamin i Polityka Prywatno<span class="s2">ś</span>ci
        obowi<span class="s2">ą</span>zuje od dnia 20.07.2022 r.
      </p>
    </li>
  </ol>
</div>`
