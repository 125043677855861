import { makeTranslations } from "../../../../utils/translations";

export const useTrans = makeTranslations({
    en_US: {
        jumpOnBoard: "So what are you waiting for?\nJump on board!",
        comingSoon: "Coming soon",
    },
    de_DE: {
        jumpOnBoard: "Also, worauf wartest Du?\nSpringe an Bord!",
        comingSoon: "Bald verfügbar",
    },
    pl_PL: {
        jumpOnBoard: "Więc na co czekasz?\n Wskakuj na pokład!",
        comingSoon: "Już wkrótce",
    }
})