/* External packages imports */
import React, { useEffect, useState } from "react";
import { Box /*, useMediaQuery, useTheme*/ } from "@mui/material";
// @ts-ignore
import Slider from "infinite-react-carousel";

/* Project scope imports. */

/* Component scope imports. */
import { BOOKCOVER_LIST } from "./variables";
import { calculateSlidesToShow, makeBookCoverUrl } from "./logic";
import { BookCover, BookCoverPaper } from "./styles";

/**
 * Infinite carousel with beautiful book covers.
 */
export const BookCarousel = ({
  reverse,
  transitionIn = true,
}: {
  reverse?: boolean;
  transitionIn?: boolean;
}) => {
  const [slidesToShow, setSlidesToShow] = React.useState(
    calculateSlidesToShow()
  );

  const [isSlider, setisSlider] = useState(false);
  useEffect(() => {
    setisSlider(true);
  }, []);

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const autoplayScroll = reverse ? -1 : 1;

  React.useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(calculateSlidesToShow());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let booksList = React.useMemo(() => {
    let list = [...BOOKCOVER_LIST];

    // Split the list in two parts and apply either the first or the second part to the carousel.
    if (!reverse) list = list.splice(0, Math.round(list.length / 2));
    else list = list.splice(Math.round(list.length / 2), list.length);

    // Shuffle the list to make the carousel look more random.
    //list = shuffle(list);

    return list;
  }, [reverse]);

  if (!booksList || !slidesToShow || !isSlider) return null;
  return (
    <Slider
      autoplay
      autoplayScroll={autoplayScroll}
      autoplaySpeed={2500}
      arrows={false}
      slidesToShow={slidesToShow}
      pauseOnHover={false}
      centerMode={true}
      rows={1}
      duration={100}
    >
      {booksList.map((fileName) => (
        <BookCoverWrapper key={fileName} src={makeBookCoverUrl(fileName)} />
      ))}
    </Slider>
  );
};

const BookCoverWrapper = ({ src }: { src: string }) => {
  return (
    <Box style={{ margin: "12px 0" }}>
      <BookCoverPaper elevation={3}>
        <BookCover src={src} alt="a book" />
      </BookCoverPaper>
    </Box>
  );
};
