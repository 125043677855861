import parse from "html-react-parser";
import "./converted.css";
import { policy } from "./privacy-policy-converted";
import { useEffect } from "react";

export function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="privacy-policy">
      <div className="privacy-converted">{parse(policy)}</div>
    </main>
  );
}
