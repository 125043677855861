import { makeTranslations } from "../../utils/translations";

export const useTrans = makeTranslations({
  en_US: {
    title: "Banners for placing on the website",
    description: "We encourage you to place a direct link to the Sowa Mobi information on the library page using a banner in one of the resolutions available below.",
    resolution: "Banners in a resolution of", 
  },
  de_DE: {
    title: "Banner zur Platzierung auf der Website", 
    description: "Wir empfehlen Ihnen, einen direkten Link zu den Informationen zur Sowa Mobi auf der Bibliotheksseite zu veröffentlichen, indem Sie ein Banner in einer der unten verfügbaren Resolutionen verwenden.",
    resolution: "Banner in einer Auflösung von",
  },
  pl_PL: {
    title: "Banery do zamieszczenia na stronie WWW",
    description: "Zachęcamy do zamieszczania na stronie informacyjnej biblioteki bezpośredniego odnośnika do informacji o aplikacji SOWA Mobi za pomocą banera w jednej z niżej dostępnych rozdzielczości.",
    resolution: `Banery w rozdzielczości`,
  },
});
