import React from "react";
import { Box } from "@mui/system";
import { Container, Fade, Grow, Stack, Typography } from "@mui/material";
import { PhoneComponent } from "../../../../components/PhoneComponent";

type PhoneRowSectionProps = {
  sectionRef?: React.MutableRefObject<HTMLDivElement>;
  active?: boolean;
  data: { title: string; screenshot: string; description?: string }[];
  id?: string;
  style?: React.CSSProperties;
};

const PhoneRowSection = ({
  sectionRef,
  active = true,
  data,
  id,
  style,
}: PhoneRowSectionProps) => {
  return (
    <Box
      minHeight={{ xs: "100vh", sm: "100vh", md: "initial" }}
      sx={style}
      width="100%"
      margin="128px 0 0 0"
      display="flex"
      alignItems="center"
      id={id}
      ref={sectionRef}
    >
      <Container maxWidth="xl">
        <Stack
          justifyContent="space-evenly"
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 6, sm: 6, md: 4, xl: 6 }}
        >
          {data.map((item, index) => (
            <VerticalPhoneWithTitle
              key={item.title + item.description}
              title={item.title}
              description={item.description}
              active={true}
              screenshot={item.screenshot}
            />
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default PhoneRowSection;

type VerticalPhoneWithTitleProps = {
  title: string;
  description?: string;
  screenshot: string;
  active: boolean;
};

const VerticalPhoneWithTitle = ({
  title,
  description,
  screenshot,
  active,
}: VerticalPhoneWithTitleProps) => (
  <Stack direction="column" justifyContent="center" alignItems="center">
    <Grow style={{ transitionDelay: "0ms" }} in={active}>
      <Box style={{ marginBottom: 18 }}>
        <PhoneComponent size={250} screenshot={screenshot} />
      </Box>
    </Grow>
    <Fade style={{ transitionDelay: "455ms" }} in={active}>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ fontWeight: 400, whiteSpace: "pre-wrap" }}
      >
        {title}
      </Typography>
    </Fade>
    {description && (
      <Fade style={{ transitionDelay: "500ms" }} in={active}>
        <Typography
          align="center"
          style={{ fontWeight: 300, whiteSpace: "pre-wrap" }}
        >
          {description}
        </Typography>
      </Fade>
    )}
  </Stack>
);
