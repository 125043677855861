import HttpStatusCode from "./HttpStatusCode";
import { CapiExec, CapiResponse } from "./types";

/**
 * @see [API Documentation](https://dav.sokrates.pl/strix/api-comm.html)
 */
export class CapiCommand {
  public exec: CapiExec;

  public result: any;

  public status: number;

  constructor(exec: CapiExec) {
    this.exec = exec;
    this.result = null;
    this.status = 0;
  }

  async setResult(response: CapiResponse) {
    const result = this.parseResult(response);
    this.result = result;
    result && (this.status = result.status);
  }

  parseResult(response: CapiResponse) {
    let failure = null;

    if (response.status !== undefined)
      response.status = parseInt(response.status as unknown as string, 10);
    else failure = "status is undefined";

    // Sprawdzamy czy status, message oraz reazon się zgadzają
    if (typeof response.status !== "number")
      failure = "status is not a number or is undefined";
    else if (
      response.message !== undefined &&
      typeof response.message !== "string"
    )
      failure = "message is not a string or is undefined";
    else if (
      response.reason !== undefined &&
      typeof response.reason !== "string"
    )
      failure = "reason is not a string or is undefined";
    else if (response.data !== undefined) {
      try {
        // Parsujemy dane
        const data =
          response.status !== HttpStatusCode.NO_CONTENT
            ? this.parseData(response.status, response.data)
            : response.data;
        return { ...response, data };
      } catch (erro) {
        const err = erro as any;
        console.log(err.message);
        return {
          status: HttpStatusCode.INTERNAL_SERVER_ERROR,
          data: response,
          reason: `parse_failure: ${err.message}`,
        };
      }
    } else return response;

    // Zwracamy błąd
    return {
      status: HttpStatusCode.INTERNAL_SERVER_ERROR,
      data: response,
      reason: `parse_failure: ${failure}`,
    };
  }

  static checkValue(val: any, name: string, ...types: string[]) {
    let t: any = typeof val;

    if (t === "object") {
      // błąd w JS sprawia, że `typeof null` zwraca "object".
      if (val === null) t = "null";
      else if (Array.isArray(val)) t = "array";
    }

    if (types.indexOf(t) < 0)
      throw new Error(`Expected ${types.join("|")} for "${name}", got: ${t}`);

    return val;
  }

  static checkField<T, F extends keyof T>(
    obj: T,
    field: F,
    ...types: string[]
  ) {
    const val = obj[field];
    CapiCommand.checkValue(obj[field], field as string, ...types);
    return val;
  }

  /** Rzuca wyjątek jeśli komenda nie zakończyła się z jednym z podanych statusów */
  ensure(...statuses: (number | string)[]) {
    const args = Array.prototype.slice.call(arguments);
    if (this.result === null) throw new Error("Command hasn't completed yet.");

    if (
      args.indexOf(this.result.status) === -1 &&
      args.indexOf(this.result.reason) === -1
    ) {
      const err = new Error(
        `Command failed with: ${this.result.status}, ${this.result.reason}`
      );
      // @ts-ignore
      err.capiStatus = this.result.status;
      // @ts-ignore
      err.capiReason = this.result.reason;
      // @ts-ignore
      err.capiData = this.result.data;
      // @ts-ignore
      err.enduserMessage = this.result.message;
      // @ts-ignore
      err.log = this.result.log;
      throw err;
    }
  }

  parseData(status: number, data: any) {
    return data;
  }
}
