/* External packages imports */
import React from "react";
import { Box } from "@mui/system";
import { Fade, Grow, Typography } from "@mui/material";

/* Project scope imports. */
import SplitSection from "../Split";
import opacLaptopImage from "assets/webp/opac_laptop.webp";

/* Component scope imports. */
import { useTrans } from "./trans";
import { BrandedBubble, StyledBubbleContainer } from "./styles";

/**
 * A section presenting web version of OPAC.
 */
const LaptopBubbleSection = ({
  sectionRef,
  active,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  active: boolean;
}) => {
  const translated = useTrans();

  return (
    <SplitSection
      style={{
        display: "flex",
        alignItems: "center",
        overflow: "initial",
        minHeight: "80vh",
        marginTop: "64px",
      }}
      sectionRef={sectionRef}
    >
      <Box position="relative" id="laptopsection">
        <Fade in={active}>
          <Typography
            variant="h4"
            style={{ fontWeight: 300, whiteSpace: "pre-wrap" }}
          >
            {translated.itsWhatYouKnow}
          </Typography>
        </Fade>
      </Box>
      <Box
        position="relative"
        marginTop={{ xs: "110px", md: "0px", maxWidth: "100%" }}
      >
        <StyledBubbleContainer>
          <Grow in={active}>
            <BrandedBubble />
          </Grow>
        </StyledBubbleContainer>
        <img
          src={opacLaptopImage}
          alt="opac laptop"
          style={{
            width: "100%",
            height: "auto",
            zIndex: 2,
            position: "relative",
          }}
        />
      </Box>
    </SplitSection>
  );
};

export default LaptopBubbleSection;
