import { makeTranslations } from "../../../../utils/translations";

export const useTrans = makeTranslations({
  en_US: {
    itsWhatYouKnow: `What you already know well.\nOn your mobile device.`,
  },
  de_DE: {
    itsWhatYouKnow: `Was Sie schon gut kennen.\nJetzt auf Ihrem mobilen Geräten.`,
  },
  pl_PL: {
    itsWhatYouKnow: `To co już dobrze znasz.\nNa Twoim urządzeniu mobilnym.`,
  },
});
