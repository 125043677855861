/* External packages imports */
import React from "react";
import { Container, Dialog, DialogTitle, Stack, Typography, List, ListItem, ListItemText, DialogContent } from "@mui/material";

/* Project scope imports. */
import sokratesLogo from "../../assets/webp/sokrates-logo.webp";
// import LocaleSwitch from "../LocaleSwitch";

/* Component scope imports. */
import { StyledFooter, StyledLink, StyledRouterLink } from "./styles";
import { useTrans } from "./trans";

/**
 * The page's footer.
 */
const Footer = () => {
    const translated = useTrans();
    const [attributionDialogOpen, setAttributionDialogOpen] = React.useState(false);

    return <>
        <StyledFooter id="footer">
            <Container maxWidth="xl">
                <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent={{ xs: "space-evenly", sm: "space-evenly", md: "space-between" }} alignItems="center" spacing={2}>
                    <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={3} alignItems="center">
                        <a href="https://sokrates.pl">
                            <img src={sokratesLogo} alt="Sokrates Software" style={{ height: "82px", width: "auto" }} />
                        </a>

                        <Typography style={{ margin: 0, opacity: 0.5 }}>© 2022 SOKRATES-software</Typography>
                    </Stack>
                    <Stack divider={<span style={{ opacity: '0.4' }}>•</span>} direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="space-evenly" alignItems="center">
                        <StyledLink target="_blank" color="inherit" underline="none" href="https://sokrates.pl/firma/">{translated.aboutUs}</StyledLink>
                        <StyledLink target="_blank" color="inherit" underline="none" href="https://sokrates.pl/kontakt/">{translated.contact}</StyledLink>
                        <StyledRouterLink to="/banners">{translated.banners}</StyledRouterLink>
                        <StyledLink color="inherit" underline="none" onClick={() => setAttributionDialogOpen(true)} >{translated.openSourceLibs}</StyledLink>
                        <StyledRouterLink to="/privacy-policy">{translated.privacyPolicy}</StyledRouterLink>
                        <StyledRouterLink to="/account-delete">{translated.deleteAccount}</StyledRouterLink>
                        {/* <Stack direction={{ xs: "column", sm: "column", md: "row" }} marginTop={{ xs: "28px", sm: "28px", md: 0 }} marginLeft={2} spacing={3} alignItems="center">
                            <LocaleSwitch />
                        </Stack> */}
                    </Stack>
                </Stack>
            </Container>
        </StyledFooter>

        { /** Dialog to display attribution */}
        <Dialog maxWidth="sm" fullWidth open={attributionDialogOpen} onClose={() => setAttributionDialogOpen(false)}>
            <DialogTitle>{translated.attributionsTitle}</DialogTitle>

            <DialogContent>
                <Typography style={{ opacity: 0.7, marginBottom: 12 }}>{translated.restriction}</Typography>

                <List sx={{ pt: 0 }}>

                    <AttributionListItem
                        name="previewed.app"
                        description="3D mobile mockups for images and video"
                        href="https://previewed.app/privacy_policy" />

                    <AttributionListItem
                        name="canva.com"
                        description="Book cover images"
                        href="https://www.canva.com/policies/free-media/" />
                </List>
            </DialogContent>
        </Dialog>
    </>;
};

const AttributionListItem = ({ href, name, description }: { href: string, name: string, description: string }) => (
    <ListItem button>
        <StyledLink color="inherit" underline="none" target="_blank" rel="noreferrer" href={href}>
            <ListItemText primary={name} secondary={description} />
        </StyledLink>
    </ListItem>
)

export default Footer;

