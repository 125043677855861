import { makeTranslations } from "../../../../utils/translations";

export const useTrans = makeTranslations({
  en_US: {
    title: "Thousands of books are waiting for you to be discover!",
  },
  de_DE: {
    title: "Tausende Bücher warten darauf, dass sie sie entdecken!",
  },
  pl_PL: {
    title: "Pokochaj bibliotekę.\nTysiące książek czeka, aż je odkryjesz!",
  },
});
