import { Card, Stack, styled } from '@mui/material';

export const StyledRoot = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: "300px",
}));

export const StyledImage = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  overflow: "auto",
  maxWidth: "100%"
}));

export const StyledPre = styled('pre')(({ theme }) => ({
  fontSize: "12px",
  userSelect: "all"
}));