/* External packages imports */
import React from "react";
import { Container, Stack, Typography, Box, ButtonBase } from "@mui/material";
import appstore from "assets/download_buttons/appstore.svg";
import playstore from "assets/download_buttons/playstore.svg";
import appgallery from "assets/download_buttons/appgallery.svg";

/* Project scope imports. */

/* Component scope imports. */
import { useTrans } from "./trans";

/**
 * Section for the download buttons.
 */
const DownloadButtonsSection = () => {
  const translated = useTrans();

  return (
    <Box
      minHeight="50vh"
      display="flex"
      alignItems="center"
      margin="64px 0"
      id="download_section"
    >
      <Container maxWidth="xl">
        <Box>
          <Typography
            variant="h4"
            align="center"
            style={{
              fontWeight: 300,
              whiteSpace: "pre-wrap",
              marginBottom: "32px",
            }}
          >
            {translated.jumpOnBoard}
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={4}
            justifyContent="center"
            alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
          >
            <ButtonBase
              href="https://play.google.com/store/apps/details?id=pl.com.sokrates.sowamobi"
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "13px",
                width: "auto",
                height: "82px",
              }}
            >
              <img
                style={{ width: "auto", height: "100%" }}
                alt="Pobierz z Google Play"
                src={playstore}
              />
            </ButtonBase>

            <ButtonBase
              href="https://apps.apple.com/pl/app/sowa-mobi/id1641753682?l=pl"
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "13px",
                width: "auto",
                height: "82px",
              }}
            >
              <img
                src={appstore}
                alt="Pobierz z App Store"
                style={{ width: "auto", height: "100%" }}
              />
            </ButtonBase>

            <Box>
              <ButtonBase
                disabled
                onClick={() => {}}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "13px",
                  width: "auto",
                  height: "82px",
                }}
              >
                <img
                  src={appgallery}
                  alt="Pobierz z AppGallery"
                  style={{ width: "auto", height: "100%", opacity: 0.4 }}
                />
              </ButtonBase>
              <Typography
                style={{ textAlign: "center", opacity: 0.6, marginTop: "14px" }}
              >
                {translated.comingSoon}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default DownloadButtonsSection;
