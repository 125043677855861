// Dependencies scoped imports
import React from 'react';
import { Fade } from '@mui/material';

// Project scoped imports

// Module scoped imports
import { StyledPhoneFrame, StyledPhoneScreenshot } from './styles';
import { PhoneComponentProps } from './types';

/**
 * @description A component displaying a PhoneFrame with a screenshot/image.
 *
 * API:
 * - inherits [Box API](https://material-ui.com/api/box/)
 */
export const PhoneComponent = ({ screenshot, size = 300, elevation, classes, ...rootProps }: PhoneComponentProps) => {
    const [isFaded, setIsFaded] = React.useState(false);
    const [activeScreenshot, setActiveScreenshot] = React.useState(screenshot);

    const handleLoad = () => {
        setIsFaded(true);
        setTimeout(() => {
            setIsFaded(false);
        }, 1)
    }

    React.useEffect(() => {
        setIsFaded(true);
        // Throttle image load to prevent flickering.
        setTimeout(() => {
            setActiveScreenshot(screenshot);
        }, 155);
    }, [screenshot]);

    return <StyledPhoneFrame
        component="figure"
        size={size}
        elevation={elevation}
        className={classes?.root}
        {...rootProps}>
        <Fade in={!isFaded} timeout={155}>
            <StyledPhoneScreenshot
                className={classes?.screenshot}
                onChange={console.log}
                onLoad={handleLoad}
                src={activeScreenshot}
                alt="phone screen" />
        </Fade>
    </StyledPhoneFrame>;
}
