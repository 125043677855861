/* External packages imports */
import React, { useContext, useMemo } from "react";
import { Hidden, Box } from "@mui/material";
import useScrollSpy from "react-use-scrollspy";

/* Project scope imports. */
import HeroSection from "./sections/Hero";
import PhoneSlideSection from "./sections/PhoneSlide";
import BookCarouselSection from "./sections/BookCarousel";
import PhoneRowSection from "./sections/PhoneRow";

import { ThemeModeContext } from "../../utils/theme";

import homeScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0000_Home.webp";
import searchScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0002_Search.webp";
import borrowedScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0004_Loans.webp";
import profileScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0003_Profile.webp";
import libraryScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0005_Libraries.webp";
import barcodeScreenshot from "../../assets/screenshots/light/light_0000s_0000s_0001_Barcode.webp";

import homeScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0000_Home.webp";
import searchScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0002_Search.webp";
import borrowedScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0004_Loans.webp";
import profileScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0003_Profile.webp";
import libraryScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0005_Libraries.webp";
import barcodeScreenshotDark from "../../assets/screenshots/dark/dark_0000s_0000s_0001_Barcode.webp";

import LaptopBubbleSection from "./sections/LaptopBubble";
import BackUpButton from "../../components/BackUpButton";

/* Component scope imports. */
import { useTrans } from "./trans";
import { Outlet } from "react-router-dom";
import DownloadButtonsSection from "./sections/DownloadButtons";

const MuiHidden = Hidden as unknown as (
  props: Record<string, any>
) => JSX.Element;

const HomePage = () => {
  const themeMode = useContext(ThemeModeContext);
  const translated = useTrans();

  const mode = themeMode.mode;

  const sectionRefs = [
    React.useRef(null as unknown as HTMLDivElement),
    React.useRef(null as unknown as HTMLDivElement),
    React.useRef(null as unknown as HTMLDivElement),
    React.useRef(null as unknown as HTMLDivElement),
    React.useRef(null as unknown as HTMLDivElement),
  ];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -(window.innerHeight / 1.3),
  });

  const screenshots = useMemo(
    () => ({
      home: mode === "light" ? homeScreenshot : homeScreenshotDark,
      search: mode === "light" ? searchScreenshot : searchScreenshotDark,
      borrowed: mode === "light" ? borrowedScreenshot : borrowedScreenshotDark,
      profile: mode === "light" ? profileScreenshot : profileScreenshotDark,
      library: mode === "light" ? libraryScreenshot : libraryScreenshotDark,
      barcode: mode === "light" ? barcodeScreenshot : barcodeScreenshotDark,
    }),
    [mode]
  );

  return (
    <>
      <Box>
        {/* 1. Hero section */}
        <HeroSection sectionRef={sectionRefs[0]} />

        {/* 2. Three main features displayed on a fixed slider. */}
        <MuiHidden mdDown>
          <PhoneSlideSection
            id="first_section"
            data={[
              {
                title: translated.phoneSlideSectionTitle[0],
                description: translated.phoneSlideSectionDescription[0],
                screenshot: screenshots.home,
              },
              {
                title: translated.phoneSlideSectionTitle[1],
                description: translated.phoneSlideSectionDescription[1],
                screenshot: screenshots.search,
              },
              {
                title: translated.phoneSlideSectionTitle[2],
                description: translated.phoneSlideSectionDescription[2],
                screenshot: screenshots.borrowed,
              },
            ]}
          />
        </MuiHidden>
        <MuiHidden mdUp>
          <PhoneRowSection
            id="first_section"
            data={[
              {
                title: translated.phoneSlideSectionTitle[0],
                description: translated.phoneSlideSectionDescription[0],
                screenshot: screenshots.home,
              },
              {
                title: translated.phoneSlideSectionTitle[1],
                description: translated.phoneSlideSectionDescription[1],
                screenshot: screenshots.search,
              },
              {
                title: translated.phoneSlideSectionTitle[2],
                description: translated.phoneSlideSectionDescription[2],
                screenshot: screenshots.borrowed,
              },
            ]}
            style={{ marginTop: "-100px" }}
            sectionRef={sectionRefs[1]}
            active={activeSection >= 1}
          />
        </MuiHidden>

        {/* 3. Book carousel section */}
        <BookCarouselSection
          sectionRef={sectionRefs[2]}
          active={activeSection >= 2}
        />

        {/* 4. Three additional features displayed in a row. */}
        <PhoneRowSection
          data={[
            {
              title: translated.threePhonesSection[0],
              screenshot: screenshots.profile,
            },
            {
              title: translated.threePhonesSection[1],
              screenshot: screenshots.library,
            },
            {
              title: translated.threePhonesSection[2],
              screenshot: screenshots.barcode,
            },
          ]}
          sectionRef={sectionRefs[3]}
          active={activeSection >= 3}
          style={{ marginTop: "-250px" }}
        />

        {/* 5. Laptop bubble section */}
        <LaptopBubbleSection
          sectionRef={sectionRefs[4]}
          active={activeSection >= 4}
        />

        {/* 6. Download section */}
        <DownloadButtonsSection />
      </Box>

      <Outlet />
      <BackUpButton />
    </>
  );
};

export default HomePage;
