import { makeTranslations } from "../../utils/translations";

export const useTrans = makeTranslations({
    en_US: {
        terms: "Terms of use",
        privacyPolicy: "Privacy policy",
        contact: "Contact",
        aboutUs: "About Us",
        openSourceLibs: "Terms of use",
        attributionsTitle: "Some of the materials were used from the following creators",
        restriction: "Excluding the following references, materials on this site are owned by SOKRATES-software and their use requires the consent of the creator.",
        banners: "Media",
        deleteAccount: "Delete account"
    },
    de_DE: {
        terms: "Terms of use",
        privacyPolicy: "Datenschutz-Bestimmungen",
        contact: "Kontakt",
        aboutUs: "Über Uns",
        openSourceLibs: "Nutzungsbedingungen",
        attributionsTitle: "Einige der Materialien wurden von den folgenden Erstellern verwendet",
        restriction: "Ausnahme der folgenden Referenzen befinden sich Materialien auf dieser Website von SOKRATES-software und deren Verwendung erfordert die Zustimmung des Erstellers.",
        banners: "Medien",
        deleteAccount: "Konto löschen"
    },
    pl_PL: {
        terms: "Terms of use",
        privacyPolicy: "Polityka prywatności",
        contact: "Kontakt",
        aboutUs: "O Nas",
        openSourceLibs: "Prawo użycia",
        attributionsTitle: "Część materiałów została wykorzystana od niżej wymienionych twórców",
        restriction: "Z wyłączeniem niżej wymienionych odniesień, materiały na tej stronie są własnością SOKRATES-software i ich użycie wymaga zgody twórcy.",
        banners: "Media",
        deleteAccount: "Usuń konto"
    }
})