import { BOOKCOVER_WIDTH } from "./variables";

/**
 * Calculates how many book covers should be displayed in the carousel.
 */
export const calculateSlidesToShow = () => {
    return ~~(window.innerWidth / (BOOKCOVER_WIDTH + 20));
}

/**
 * Returns a full url to a book cover image.
 */
export const makeBookCoverUrl = (bookFileName: string) => {
    return `${process.env.PUBLIC_URL}/assets/covers/webp/${bookFileName}`;
}