/* External packages imports */
import React from "react";
import { Box, Typography } from "@mui/material";
// @ts-ignore

/* Project scope imports. */
import EB from "../../../../utils/ErrorBoundary";

/* Component scope imports. */
import { CarouselContainer, StyledBackdrop } from "./styles";
import { useTrans } from "./trans";
import { BookCarousel } from "./BookCarousel";

type BookCarouselSectionProps = {
  sectionRef?: React.Ref<HTMLDivElement>;
  active?: boolean;
};

const ErrorBoundary = EB as unknown as (
  props: Record<string, any>
) => JSX.Element;

const BookCarouselSection = ({
  sectionRef: ref,
  active,
}: BookCarouselSectionProps) => {
  const { title } = useTrans();

  return (
    <Box height="auto" style={{ margin: "128px 0 0 0" }} ref={ref}>
      <Box marginTop="auto" marginBottom="auto">
        <Typography
          align="center"
          variant="h4"
          gutterBottom
          style={{ marginBottom: "48px", fontWeight: 300 }}
        >
          {title}
        </Typography>

        <ErrorBoundary>
          <CarouselContainer flex={1}>
            <BookCarousel transitionIn={active} />
            <BookCarousel transitionIn={active} reverse />
            <StyledBackdrop />
          </CarouselContainer>
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default BookCarouselSection;
