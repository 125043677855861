import { uniqueId } from "lodash";
import { CapiCommand } from "./command";
import { CapiAuth, CapiExec } from "./types";

// TEST

const toJSON = JSON.stringify;

type CapiClientConfig = {
  auth?: CapiAuth;
  lang?: string;
  mode?: number;
  debug?: boolean;
  wait?: number;
  exec?: CapiExec[];
};

/**
 * API Documentation - {@link https://dav.sokrates.pl/strix/api-comm.html}
 */
export class CapiClient {
  id: string;

  url: string;

  auth: CapiClientConfig["auth"];

  lang: CapiClientConfig["lang"];

  mode: CapiClientConfig["mode"];

  debug: CapiClientConfig["debug"];

  wait: CapiClientConfig["wait"];

  exec: CapiClientConfig["exec"];

  constructor(url?: string, config?: CapiClientConfig) {
    this.id = uniqueId();
    this.url = url ?? "";
    this.auth = config?.auth;
    this.mode = config?.mode || 1;
    this.lang = config?.lang;
    this.debug = false;
    this.wait = 0;
    this.exec = [];
  }

  async execute(...commands: CapiCommand[]) {
    const { url } = this;
    const { auth } = this;
    const { lang } = this;
    const { mode } = this;

    if (!url) return [];

    let response: Response;
    const commandsLeft = commands;

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    try {
      response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers,
        body: toJSON({
          auth,
          lang,
          mode,
          log: 0,
          exec: commandsLeft.map((command) => {
            console.log(`🕐 Executing command ${command.exec[0]} (${this.id})`);
            // console.time(`🕐 Finished execution of command ${command.exec[0]} (${this.id})`);
            return command.exec;
          }),
        }),
      });
    } catch (err: any) {
      console.error(err);
      throw err;
    }

    const results = await response.json();

    for (let i = 0; i < commandsLeft.length; i += 1) {
      const command = commandsLeft[i];
      const result = results[i];

      console.group("🌐", command.exec[0], `(${this.id})`);
      console.log("🔒", auth);
      console.log("🤯", headers);
      console.log("⬆️", url, command.exec[1], command.exec[2]);

      if (result?.reason || result?.message) {
        console.log(
          "⬇️",
          result.status,
          `${result.message} (${result.reason})`,
          result.data
        );
      } else {
        console.log("⬇️", result.status, result.data);
      }
      console.groupEnd();
      // console.timeEnd(`🕐 Finished execution of command ${command.exec[0]} (${this.id})`);
      console.log(
        `🕐 Finished execution of command ${command.exec[0]} (${this.id})`
      );

      command.setResult(result);
    }

    return commands;
  }

  executeSingle<R = any>(
    command: CapiCommand
  ): Promise<{ data: R; status: number; reason?: string; message?: string }> {
    return this.execute(command).then((commands) => commands[0]?.result);
  }

  copyWith(obj = {}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.assign(new CapiClient(), this, obj);
  }
}
