import { styled, Dialog, Typography } from '@mui/material';

export const StyledRoot = styled(Dialog)(({ theme }) => ({
}));

export const StyledSloganTypography = styled(Typography)(({ theme }) => ({
    maxWidth: "50%",
    opacity: 0.7,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        maxWidth: "100%",
    }
}));