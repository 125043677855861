import { Button, Paper, TextField, Typography, useTheme } from "@mui/material";
import { FormAutocomplete } from "components/Form/FormAutocomplete";
import { FormTextField } from "components/Form/FormTextField";
import { useForm } from "react-hook-form";
import { useLibraries } from "./useLibraries";
import { useEffect, useRef, useState } from "react";
import { CapiCommand } from "capi/command";
import { CapiClient } from "capi/client";
import { v4 as uuid } from "uuid";

export function AccountDeletePage() {
  const sessionRef = useRef(uuid());
  const { libOptions, loading } = useLibraries();

  const [activeLib, setActiveLib] = useState<any>();
  const [response, setResponse] = useState<ResponseState>();

  const { control, handleSubmit, reset } = useForm<DeleteForm>();
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="account-delete">
      <div className="account-delete__info">
        <Typography variant="h4">
          Zgłoszenie prośby o usunięcie konta.
        </Typography>
        <Typography>
          Jeśli Twoje konto nie zostało jeszcze potwierdzone w bibliotece,
          możesz je samodzielnie usunąć w aplikacji Sowa MOBI.
        </Typography>
        <Typography>
          W celu usunięcia konta potwierdzonego wizytą w bibliotece, prosimy
          wypełnić poniższy formularz. Pamiętaj: dane do konta muszą być zgodne
          z Twoimi danymi w profilu aplikacji Sowa MOBI. Numer karty
          bibliotecznej nie jest wymagany, ale przyspieszy weryfikację
          zgłoszenia w bibliotece.
        </Typography>
        <Typography>Konto nie może być usunięte, jeśli:</Typography>
        <Typography style={{ marginLeft: 20, marginTop: -20 }}>
          a) posiadasz wypożyczone i niezwrócone materiały biblioteczne,
          <br /> b) nie zostały uregulowane należności z tytułu przetrzymania
          materiałów bibliotecznych, <br /> c) została wpłacona kaucja, którą
          biblioteka musi zwrócić, <br /> d) nie zostały spełnione inne warunki
          określone w regulaminie wybranej biblioteki.
        </Typography>
        <Typography>
          Ponadto biblioteka może wymagać osobistego potwierdzenia tożsamości i
          własności konta bibliotecznego.
        </Typography>
      </div>
      <Paper key={formKey} className="account-delete__form">
        <form onSubmit={handleSubmit(handleUserDelete)}>
          <FormAutocomplete
            name="library"
            control={control}
            disablePortal
            fullWidth
            onChange={(_, lib) => setActiveLib(lib)}
            noOptionsText={"Brak wyników"}
            options={libOptions}
            renderInput={(params) => (
              <TextField {...params} label="Biblioteka" required size="small" />
            )}
            renderOption={(props: any, option: any) => (
              <div {...props} className="account-delete-lib-option">
                <img
                  src={`data:image/jpeg;base64,${option.logo}`}
                  alt={option.label}
                />
                <span>{option.label}</span>
              </div>
            )}
          />
          <TermsPageLink activeLib={activeLib} session={sessionRef.current} />
          <FormTextField
            name="email"
            control={control}
            label="Adres e-mail"
            fullWidth
            required
            size="small"
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email jest niepoprawny",
              },
            }}
          />
          <FormTextField
            name="firstName"
            control={control}
            label="Imię"
            fullWidth
            required
            size="small"
          />
          <FormTextField
            name="lastName"
            control={control}
            label="Nazwisko"
            fullWidth
            required
            size="small"
          />
          <FormTextField
            name="identifier"
            control={control}
            label="Nr karty bibliotecznej"
            fullWidth
            multiline
            size="small"
          />
          <FormTextField
            name="reason"
            control={control}
            label="Powód usuwania konta"
            fullWidth
            required
            multiline
            size="small"
            rows={3}
            rules={{
              minLength: {
                value: 20,
                message: "Wymagane co najmniej 20 znaków",
              },
              maxLength: {
                value: 400,
                message: "Przekroczono dopuszczalną ilość znaków",
              },
            }}
          />
          {response && (
            <RequestMessage message={response.message} type={response.type} />
          )}
          <Button variant="contained" style={{ marginTop: 20 }} type="submit">
            Potwierdź
          </Button>
        </form>
      </Paper>
    </main>
  );

  function handleUserDelete(data: DeleteForm) {
    exeUserSignout(data, activeLib, sessionRef.current).then((res) => {
      if (res.status === 204) {
        setActiveLib(undefined);
        setResponse({
          type: "success",
          message: "Wniosek został pomyślnie wysłany",
        });
        reset();
        setFormKey((prev) => prev + 1);
      } else {
        setResponse({ type: "error", message: res.message || "Wystąpił błąd" });
      }
    });
  }
}

function TermsPageLink({
  activeLib,
  session,
}: {
  activeLib: any;
  session: string;
}) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (activeLib) {
      fetchParam();
    }

    async function fetchParam() {
      setUrl("");
      const url = activeLib.sowa?.catalogues?.[0]?.url;
      const client = new CapiClient(url, {
        auth: [112, session, activeLib.domain],
      });

      const termsPageRq = await client.executeSingle(
        new ParamsGet(["opac.registration.default.regulations"])
      );
      setUrl(Object.values(termsPageRq?.data || {})[0] as string);
    }
  }, [activeLib?.domain]);

  if (url)
    return (
      <a href={url} target="_blank" rel="noreferrer">
        Regulamin biblioteki
      </a>
    );
  return null;
}

function RequestMessage({ message, type }: ResponseState) {
  const theme = useTheme();

  const color =
    type === "error" ? theme.palette.error.main : theme.palette.success.main;

  return (
    <div
      className="account-delete__message"
      style={{
        border: `1px solid ${color}`,
      }}
    >
      {message}
      <div
        className="account-delete__message__bg"
        style={{
          backgroundColor: color,
        }}
      />
    </div>
  );
}

async function exeUserSignout(
  data: DeleteForm,
  activeLib: any,
  session: string
) {
  const client = new CapiClient(activeLib?.folks.url, {
    auth: [112, session, activeLib?.domain],
  });

  const result = await client.executeSingle(
    new UserSignout(data.library, data.email, data.firstName, data.lastName, {
      identifier: data.identifier || undefined,
      reason: data.reason,
    })
  );

  return result;
}

class UserSignout extends CapiCommand {
  constructor(
    domain: string,
    email: string,
    firstName: string,
    lastName: string,
    kwargs: any = {}
  ) {
    super(["folksUserSignout", [domain, email, firstName, lastName], kwargs]);
  }
}

class ParamsGet extends CapiCommand {
  constructor(names: string[] = []) {
    super(["sowaParamsGet", [names, []]]);
  }
}

interface ResponseState {
  message: string;
  type: "error" | "success";
}

interface DeleteForm {
  library: string;
  email: string;
  firstName: string;
  lastName: string;
  identifier: string;
  reason: string;
}
