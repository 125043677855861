import { calculateBookCoverHeight } from "../../../../utils/math";

export const BOOKCOVER_WIDTH = 153.333;
export const BOOKCOVER_HEIGHT = calculateBookCoverHeight(BOOKCOVER_WIDTH);

export const BOOKCOVER_LIST = [
  "Black & Yellow Modern Architecture Book Cover.webp",
  "Blue and White Building Autobiography Book Cover.webp",
  "Blue, Yellow and Red Illustrated Children's Book Cover.webp",
  "Colorful Abstract Adventure Children's Book Cover.webp",
  "Couple Silhouette Romance_Chick Lit Book Cover.webp",
  "Home Thriller E-Book Cover.webp",
  "Male Photo Memoir_Inspirational Book Cover.webp",
  "Monochrome Thriller Forest Photo Book Cover.webp",
  "Pink, Black and Orange Couple Illustration Romance_Chick Lit Book Cover.webp",
  "Purple and Red Leaves Illustration Children's Book Cover.webp",
  "Red and Beige Cute Illustration Young Adult Book Cover.webp",
  "White and Yellow Typographic Self-Help Book Cover.webp",
  "White Minimalist Cookbook Book Cover.webp",
  "White Photo Garden Guide Book Cover.webp",
  "White, Black and Red Modern Self Help Book Cover.webp",
  "Blue and Yellow Simple Scenery Photograph Nature Guide Wattpad Book Cover.webp",
  "Yellow and Red Bold and Bright Retro Chic Romance Wattpad Book Cover.webp",
  "Pink and White Cute Cupcake Desserts Photograph Recipe Wattpad Book Cover.webp",
  "Pizza Dough Ebook Cover.webp",
  "Orange Yellow and White Simple Floral Illustration Romance Wattpad Book Cover.webp",
  "Orange and Yellow Drinks Bold and Bright Retro Chic Romance Wattpad Book Cover.webp",
  "White Brown and Blue Classic Happy Couple Photo Typography Romance Wattpad Book Cover.webp",
];
