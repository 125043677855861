import { createLittera } from "@assembless/react-littera"


const LOCALES = ["en_US", "de_DE", "pl_PL"] as const;

const PRESET = {
  "en_US": {
    "language": "Language",
    yes: "Yes",
    no: "No",
    close: "Close"
  },
  "de_DE": {
    "language": "Sprache",
    yes: "Ja",
    no: "Nein",
    close: "Schließen"
  },
  "pl_PL": {
    "language": "Język",
    yes: "Tak",
    no: "Nie",
    close: "Zamknij"
  }
}
const { LitteraService, makeTranslations, useLitteraMethods } = createLittera(LOCALES, PRESET);

function getInitialLocale(): "en_US" | "de_DE" | "pl_PL" {
  return "pl_PL"; // Temporary disabled due to no translations supported in the mobile app.

  // const locale = localStorage.getItem("locale");
  // if (locale) {
  //   return locale as "en_US" | "de_DE" | "pl_PL";
  // }
  // return "pl_PL";
}

export { LitteraService, makeTranslations, useLitteraMethods, getInitialLocale };