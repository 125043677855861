import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, useMediaQuery } from '@mui/material';


const light_theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#5484B2',
        },
        secondary: {
            main: '#2f95dc',
        },
        background: {
            paper: "#F7F7F7"
        }
    }
});

const dark_theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#019BDD',
        },
        secondary: {
            main: '#2f95dc',
        },
        background: {
            paper: "#212121"
        }
    },
});

export const ThemeModeContext = React.createContext({
    mode: "light",
    toggle: () => { },
});

export const ThemeService = ({ children }: { children: React.ReactNode }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState(getPreferedThemeMode());

    function getPreferedThemeMode(): "dark" | "light" {
        const storageThemeMode = localStorage.getItem("themeMode");
        const preferedMode = prefersDarkMode ? "dark" : "light";

        if (storageThemeMode === "dark" || storageThemeMode === "light") {
            return storageThemeMode;
        } else {
            localStorage.setItem("themeMode", preferedMode);
        }

        return preferedMode;
    }

    const theme = useMemo(() =>
        mode === "light" ?
            light_theme : dark_theme,
        [mode]);

    const toggle = () => {
        setMode(m => {
            const nextMode = m === "light" ? "dark" : "light";

            localStorage.setItem("themeMode", nextMode);

            return nextMode
        });

    };

    return <ThemeModeContext.Provider value={{ mode, toggle }}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    </ThemeModeContext.Provider>
}

export const useThemeMode = () => {
    const context = React.useContext(ThemeModeContext);
    if (context === undefined) {
        throw new Error('useThemeMode must be used within a ThemeModeProvider');
    }
    return context;
}